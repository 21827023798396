'use client';

import config from '@/config';
import Logo from '@/src/components/Logo';
import Link from 'next/link';
import * as React from 'react';

const links: {
  href: string;
  label: string;
}[] = [
  {
    href: '/#pricing',
    label: 'Pricing',
  },
  {
    href: '/#testimonials',
    label: 'Reviews',
  },
  {
    href: `${config.appUrl}/play`,
    label: 'Student Join',
  },
];

export default function HeaderNew() {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <header className="relative z-10 py-4 md:py-6 bg-gray-50">
      <div className="container px-4 mx-auto sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between">
          <div className="flex-shrink-0">
            {/* <a
              href="#"
              title=""
              className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              <img
                className="w-auto h-8"
                src="https://cdn.rareblocks.xyz/collection/clarity/images/logo.svg"
                alt=""
              />
            </a> */}
            <Link
              className="flex items-center gap-2 shrink-0 "
              href="/"
              title={`${config.appName} hompage`}
            >
              <Logo />
              <span className="font-extrabold text-lg">{config.appName}</span>
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <button
              type="button"
              className="text-gray-900"
              onClick={() => setExpanded((prev) => !prev)}
            >
              {!expanded ? (
                <span>
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </span>
              ) : (
                <span>
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              )}
            </button>
          </div>

          {/* Desktop menu */}
          <div className="hidden md:flex md:items-center md:justify-center md:space-x-10 md:absolute md:inset-y-0 md:left-1/2 md:-translate-x-1/2 lg:space-x-16">
            {links.map((link) => (
              <a
                href={link.href}
                key={link.href}
                title=""
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              >
                {' '}
                {link.label}
              </a>
            ))}
          </div>

          <div className="hidden md:flex items-center gap-8">
            <a
              href={`${config.appUrl}/login`}
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              Login
            </a>
            <a
              href={`${config.appUrl}/register`}
              title=""
              className="btn btn-primary rounded"
              role="button"
            >
              Try for free
            </a>
          </div>
        </div>

        {/* Mobile menu */}
        {expanded && (
          <nav>
            <div className="px-1 py-8">
              <div className="grid gap-y-7">
                {links.map((link) => (
                  <a
                    href={link.href}
                    key={link.href}
                    title=""
                    className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                  >
                    {' '}
                    {link.label}
                  </a>
                ))}

                <a
                  href={`${config.appUrl}/login`}
                  className="text-base font-medium p-3 -m-3 text-gray-900 transition-all duration-200 rounded focus:outline-none hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  Login
                </a>

                <a
                  href={`${config.appUrl}/register`}
                  title=""
                  className="btn btn-primary"
                  role="button"
                >
                  Try for free
                </a>
              </div>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}
