'use client';

import mixpanel from 'mixpanel-browser';
import * as React from 'react';

type Props = {
  variant: 'a' | 'b';
};

export default function EventViewHomepage({ variant }: Props) {
  React.useEffect(() => {
    mixpanel.track('homepage_viewed', {
      variant,
    });
  }, [variant]);

  return null;
}
