'use client';

import config from '@/config';
import Image from 'next/image';

/**
 * The phone frame image is 2961 x 6253px on the inside screen which is a 0.4735 aspect ratio
 * Video film on 390 x 823 (using devtool responsive setting)
 */
export default function SectionPlayAnywhere() {
  return (
    <section className="bg-base-100 flex flex-col items-center py-24 px-8">
      <h2 className="font-extrabold text-3xl lg:text-5xl tracking-tight mb-8 text-center">
        Play from anywhere 🌎
      </h2>
      <div className="text-base-content/80 leading-relaxed mb-8 lg:text-lg max-w-2xl text-center">
        Players get their own view of the map, so you&apos;re not limited to the
        classroom environment to play QuizWhizzer. You can host your quiz from
        any location and players can join from anywhere in the world!
      </div>
      <div className="w-[214px] h-[445px] rounded-[34px] lg:w-[390px] lg:h-[812px] xl:w-[406px] xl:h-[845px] relative overflow-hidden lg:rounded-[71px]">
        <Image
          src={`${config.imagesBaseUrl}/landing/phone frame.png`}
          fill
          alt="Phone"
          className="z-10"
        />
        <video
          width="100%"
          autoPlay
          muted
          loop
          className="w-[calc(100%-18px)] relative left-[9px] top-[1px] h-full object-cover"
        >
          <source
            src={`${config.imagesBaseUrl}/landing/Player demo 390x823.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
}
