const devConfig = {
  appUrl: 'http://localhost:3000',
  serverUrl: 'http://localhost:8000',
  cookieDomain: 'localhost',
};

const environments = {
  production: {
    appUrl: 'https://app.quizwhizzer.com',
    serverUrl: 'https://api.quizwhizzer.com',
    cookieDomain: '.quizwhizzer.com',
  },
  staging: {
    appUrl: 'https://app.qw-staging.com',
    serverUrl: 'https://api.qw-staging.com',
    cookieDomain: '.qw-staging.com',
  },
  development: devConfig,
  test: devConfig,
};

export default environments[
  (process.env.NEXT_PUBLIC_APP_ENV as keyof typeof environments) ??
    'development'
];
