import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/pods/home/EventViewHomepage.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/pods/home/FAQ.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/pods/home/FeaturesAccordian.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/pods/home/FloatingPowerup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/pods/home/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/pods/home/HeaderNew.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/pods/home/Logos.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/pods/home/Pricing.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/pods/home/SectionPlayAnywhere.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/pods/home/SectionStats.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/pods/home/Testimonials.tsx");
