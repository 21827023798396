'use client';

import Image from 'next/image';
import styles from './Logos.module.css';
import { cn } from '@/src/libs/utils';
import React from 'react';

export default function Logos() {
  const logoFilenames: Array<{
    filename: string;
    alt: string;
    customSize?: { width: number; height: number };
  }> = [
    { filename: 'newcastle-sixth-form.png', alt: 'Newcastle Sixth Form' },
    { filename: 'fcps.png', alt: 'Fairfax County Public Schools' },
    {
      filename: 'allentown-school-district.png',
      alt: 'Allentown School District',
    },
    {
      filename: 'school-district-of-philadelphia.png',
      alt: 'School District of Philadelphia',
    },
    {
      filename: 'hesperia-unified-school-district.png',
      alt: 'Hesperia Unified School District',
    },
    {
      filename: 'jackson-school-district.png',
      alt: 'Jackson School District',
    },
    {
      filename: 'duval-county-public-schools.png',
      alt: 'Duval County Public Schools',
    },
    {
      filename: 'tulsa-public-schools.png',
      alt: 'Tulsa Public Schools',
    },
    {
      filename: 'irvine-unified-school-district.png',
      alt: 'Irvine Unified School District',
    },
    {
      filename: 'marana-unified-school-district.png',
      alt: 'Marana Unified School District',
    },
    {
      filename: 'harmony-public-schools.png',
      alt: 'Harmony Public Schools',
    },
    {
      filename: 'harford-county-public-schools.png',
      alt: 'Harford County Public Schools',
    },
    {
      filename: 'baldwin-school.png',
      alt: 'Baldwin School',
    },
    {
      filename: 'granite-school-district.png',
      alt: 'Granite School District',
    },
    {
      filename: 'seattle-public-schools.png',
      alt: 'Seattle Public Schools',
    },
    {
      filename: 'anne-arundel-public-schools.png',
      alt: 'Anne Arundel County Public Schools',
    },
    {
      filename: 'alpine-school-district.png',
      alt: 'Alpine School District',
    },
    {
      filename: 'evergreen-public-schools.png',
      alt: 'Evergreen Public Schools',
    },
    {
      filename: 'montgomery-county-public-schools.png',
      alt: 'Montgomery County Public Schools',
    },
    {
      filename: 'st-louis-public-schools.png',
      alt: 'St. Louis Public Schools',
    },
  ];

  return (
    <div
      className={cn(
        'flex flex-col gap-4 overflow-hidden relative',
        styles.container
      )}
    >
      <AnimatedRow logos={logoFilenames.slice(0, logoFilenames.length / 2)} />
      <AnimatedRow
        logos={logoFilenames.slice(logoFilenames.length / 2)}
        offset
      />
    </div>
  );
}

function AnimatedRow({
  logos,
  offset,
}: {
  logos: Array<{
    filename: string;
    alt: string;
  }>;
  offset?: boolean;
}) {
  return (
    <div
      className={cn(
        'whitespace-nowrap relative',
        styles.logos,
        offset && 'ml-[-89px]'
      )}
    >
      <div className={styles.logosSlide}>
        {logos.map((logo) => (
          <Image
            key={logo.alt}
            src={`/logos/${logo.filename}`}
            alt={logo.alt}
            width={130}
            height={73}
            className={styles.logo}
          />
        ))}
      </div>
      <div className={styles.logosSlide}>
        {logos.map((logo) => (
          <Image
            key={logo.alt}
            src={`/logos/${logo.filename}`}
            alt={logo.alt}
            width={130}
            height={73}
            className={styles.logo}
          />
        ))}
      </div>
    </div>
  );
}
