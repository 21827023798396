'use client';

import * as React from 'react';
import Link from 'next/link';

type StatBox = {
  quizTitle: string;
  description: string;
  boldStat: string;
  link: string;
};

const stats: StatBox[] = [
  {
    quizTitle: 'Present simple tense',
    description: 'Students took this quiz 2.3 times on average.',
    boldStat: '2.3x',
    link: 'https://app.quizwhizzer.com/quiz/5d80b50a7d1fe1001666be0e',
  },
  {
    quizTitle: 'Comparing Decimals',
    description: 'Average time to finish was 8m 47s.',
    boldStat: '8m 47s',
    link: 'https://app.quizwhizzer.com/quiz/62ca810c0ecf070016e52613',
  },
  {
    quizTitle: 'Atoms and Matter',
    description: 'Average score across attempts was 78%.',
    boldStat: '78%',
    link: 'https://app.quizwhizzer.com/quiz/67230b751a7aa23c018aea6e',
  },
  {
    quizTitle: 'Revolutionary War',
    description: 'Scores improved by 15% on the second try.',
    boldStat: '+15%',
    link: 'https://app.quizwhizzer.com/quiz/61ace70de90873000f8cb004',
  },
  {
    quizTitle: 'The States Capitals',
    description: '88% fully completed the quiz.',
    boldStat: '88%',
    link: 'https://app.quizwhizzer.com/quiz/6161ee0555975b000f8256d1',
  },
  {
    quizTitle: '8th Grade Science',
    description: '36% retook the quiz and improved their score.',
    boldStat: '36%',
    link: 'https://app.quizwhizzer.com/quiz/5d97ac08556b242254fc1045',
  },
  {
    quizTitle: 'Chemistry 116 Lab Final',
    description: '18% returned within 48 hours to retry.',
    boldStat: '18%',
    link: 'https://app.quizwhizzer.com/quiz/619b3ff7785e51000f0e9ec6',
  },
  {
    quizTitle: 'Inventions of the Industrial Revolution',
    description: '22% accessed extra study materials',
    boldStat: '22%',
    link: 'https://app.quizwhizzer.com/quiz/5ec8f7e26c664300174ec89e',
  },
  {
    quizTitle: 'Pre-Columbian Civilizations',
    description: '1.8x more likely to retry if they scored below 70%.',
    boldStat: '1.8x',
    link: 'https://app.quizwhizzer.com/quiz/60e379128a9ef3000f12070e',
  },
];

export default function SectionStats() {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % stats.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + stats.length) % stats.length);
  };

  let offsetAmount = 340 + 32;

  if (
    containerRef.current?.clientWidth &&
    containerRef.current.clientWidth - 64 < 340
  ) {
    // -64 for the padding on either side, 32 gap
    offsetAmount = containerRef.current.clientWidth - 64 + 32;
  }

  const totalSlidesVisible =
    (containerRef.current?.offsetWidth ?? 0) / offsetAmount;

  const isLastSlideVisible = currentSlide + totalSlidesVisible >= stats.length;

  return (
    <section className="bg-base-200/50 py-16 md:py-32">
      <div className="max-w-7xl px-8 pb-12 mx-auto">
        <h2 className="max-w-4xl font-extrabold text-4xl md:text-5xl tracking-tight mb-6 md:mb-8">
          Levelling up Learning
        </h2>
        <p>
          With QuizWhizzer, teachers save up to 3 hours of prep time by using
          customisable quizzes that keep students engaged and learning. Students
          learn more effectively, teachers save time, and classrooms come to
          life.
        </p>
      </div>

      <div className="relative">
        <div className="overflow-hidden px-8" ref={containerRef}>
          <div
            className="flex gap-8 transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${currentSlide * offsetAmount}px)`,
            }}
          >
            {stats.map((stat) => (
              <Link
                className="flex-shrink-0 w-[min(100%,340px)] p-8 border-2 border-primary rounded-lg bg-white"
                href={stat.link}
                key={stat.link}
              >
                <p className="text-4xl font-bold mb-2">{stat.boldStat}</p>
                <p className="font-bold text-xl">{stat.quizTitle}</p>
                <p>{stat.description}</p>
              </Link>
            ))}
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-6 px-8">
          <button
            onClick={prevSlide}
            className="p-4 rounded-full bg-white shadow-lg hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
            aria-label="Previous slide"
            disabled={currentSlide === 0}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          <button
            onClick={nextSlide}
            className="p-4 rounded-full bg-white shadow-lg hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
            aria-label="Next slide"
            disabled={isLastSlideVisible || currentSlide === stats.length - 1}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}
